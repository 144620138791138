<template>
  <div>
    <validation-observer ref="formRules">
      <!-- head -->
      <div>
        <!-- <b-row> {{infos}}</b-row> -->
        <b-card>
          <b-card-header>
            <b-row no-gutters class="w-100">
              <b-col md="12">
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button
                      size="lg"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      title="Informations"
                      v-b-popover.hover.focus="
                        '⚪️ Le questionnaire de renseignements de départ à l\'aéroport de Cotonou est éditable par les voyageurs partant du Bénin par l\'aéroport et les annexes. ⚪️ Il permet de collecter les données sur les voyageurs et leur séjours. ⚪️ Le remplissage de la fiche ne nécessite pas une connexion ou la création d\'un compte.'
                      "
                    >
                      <feather-icon icon="BellIcon" />
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <b-card-img
                  :src="
                    require('@/assets/images/illustration/Globalization-pana.png')
                  "
                  alt="Image"
                  class="rounded-0 my-2"
                >
                </b-card-img>
              </b-col>
              <b-col
                md="10"
                class="d-flex align-items-center justify-content-center"
              >
                <div>
                  <h1 class="m-auto text-center">
                    FICHE D'ENQUETE SUR LE TOURISME INTERNE
                  </h1>
                  <!-- <br /> -->
                  <!-- <h4 class="m-auto text-center">
                    Location de véhicules sans opérateur
                  </h4> -->
                </div>
              </b-col>
            </b-row>
          </b-card-header>
          <content-loader
            v-if="isCheckingForLastForm"
            view-box="0 0 400 460"
            :speed="2"
            primary-color="#f3f3f3"
            secondary-color="#ecebeb"
          >
            <circle cx="29" cy="30" r="17" />
            <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
            <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
            <rect x="-7" y="60" rx="2" ry="2" width="408" height="132"
          /></content-loader>
        </b-card>
      </div>
      <b-row v-if="!isCheckingForLastForm">
        <!-- navigation -->
        <b-col lg="3" class="d-none d-lg-block">
          <div>
            <b-card>
              <b-list-group>
                <b-list-group-item href="#identification">
                  IDENTIFICATION
                </b-list-group-item>
                <b-list-group-item href="#classification">
                  CLASSIFICATION DES VOYAGES ET/OU EXCURSIONS
                </b-list-group-item>
                <b-list-group-item href="#voyagesBenin">
                  DETAILS SUR LE VOYAGE ET LES DÉPENSES AU BENIN
                </b-list-group-item>
                <b-list-group-item href="#voyagesEtranger">
                  DETAILS SUR LE VOYAGE ET LES DÉPENSES A L'ETRANGER
                </b-list-group-item>
                <b-list-group-item href="#excursionsBenin">
                  EXCURSIONS AU BENIN
                </b-list-group-item>
                <b-list-group-item href="#excursionsEtranger">
                  EXCURSIONS A L'ÉTRANGER
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
          <div class="text-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              block
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
        <!-- body -->
        <b-col lg="9" class="pb-2">
          <!-- IDENTIFICATION  -->
          <div id="identification">
            <b-card>
              <b-card-header class="d-flex justify-content-between">
                <h3>IDENTIFICATION</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <b-row class="mt-2">
                  <b-col md="6">
                    <b-form-group label-cols-lg="3" label="Nom">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model.number="infos.lastname"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label-cols-lg="3" label="Prénom(s)">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model.number="infos.firstname"
                          type="text"
                          placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </div>

          <!-- CLASSIFICATION DES VOYAGES ET/OU EXCURSIONS  -->
          <div>
            <b-card id="classification">
              <b-card-header class="d-flex justify-content-between">
                <h3>CLASSIFICATION DES VOYAGES ET/OU EXCURSIONS</h3>
              </b-card-header>
              <hr />
              <b-card-body>
                <!-- voyage dans le dernier trimestre -->
                <b-row class="mt-2">
                  <b-col md="12">
                    <div>
                      Pouvez-vous nous dire si vous ou toute autre personne de
                      votre foyer avez réalisé un voyage ou une excursion au
                      cours du dernier trimestre ?
                    </div>
                  </b-col>
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.voyagesDernierTrimestre"
                      :options="optionsOuiNon"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-row>
                <!-- raison du non voyage -->
                <b-row
                  class="mt-1"
                  v-if="infos.voyagesDernierTrimestre == 'Non'"
                >
                  <b-col md="12"
                    ><div>
                      Raison pour laquelle vous n'avez pas réalisé de voyage au
                      cours du dernier trimestre :
                    </div></b-col
                  >
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-radio-group
                      v-model="infos.raisonNonVoyage"
                      :options="optionsNonVoyage"
                      class="demo-inline-spacing mb-1 ml-1"
                      value-field="value"
                      text-field="text"
                      disabled-field="disabled"
                    />
                    <small class="text-danger ml-1">{{ errors[0] }}</small>
                  </validation-provider>
                  <b-col
                    v-if="infos.raisonNonVoyage == 'Autres'"
                    class=""
                    md="10"
                  >
                    <b-form-group
                      label-cols="5"
                      label-cols-lg="3"
                      label="Veuillez la préciser"
                    >
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-input
                          v-model="raisonNonVoyageAutre"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col v-else class="mt-1" md="6" />
                </b-row>
                <!-- précisions -->
                <div v-if="infos.voyagesDernierTrimestre == 'Oui'">
                  <div>Précisez si vous avez effectué :</div>
                  <b-row class="mt-1">
                    <b-col md="12">
                      <div>Des voyages au Bénin</div>
                    </b-col>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.voyageBenin.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-col
                      v-if="infos.voyageBenin.statut == 'Oui'"
                      class="mt-1"
                      md="10"
                    >
                      <b-form-group
                        label-cols="5"
                        label-cols-lg="3"
                        label="Le nombre"
                        class="text-right"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="infos.voyageBenin.nombre"
                            type="number"
                            min="1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col v-else class="mt-1" md="6" />
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="12">
                      <div>Des voyages à l'étranger</div>
                    </b-col>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.voyageEtranger.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-col
                      v-if="infos.voyageEtranger.statut == 'Oui'"
                      class="mt-1"
                      md="10"
                    >
                      <b-form-group
                        label-cols="5"
                        label-cols-lg="3"
                        label="Le nombre"
                        class="text-right"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="infos.voyageEtranger.nombre"
                            type="number"
                            min="1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col v-else class="mt-1" md="6" />
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="12">
                      <div>Des excursions au Bénin</div>
                    </b-col>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.ExcursionBenin.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-col
                      v-if="infos.ExcursionBenin.statut == 'Oui'"
                      class="mt-1"
                      md="10"
                    >
                      <b-form-group
                        label-cols="5"
                        label-cols-lg="3"
                        label="Le nombre"
                        class="text-right"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="infos.ExcursionBenin.nombre"
                            type="number"
                            min="1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col v-else class="mt-1" md="6" />
                  </b-row>
                  <b-row class="mt-1">
                    <b-col md="12">
                      <div>Des excursions à l'étranger</div>
                    </b-col>
                    <validation-provider #default="{ errors }" rules="required">
                      <b-form-radio-group
                        v-model="infos.ExcursionEtranger.statut"
                        :options="optionsOuiNon"
                        class="demo-inline-spacing mb-1 ml-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                      <small class="text-danger ml-1">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-col
                      v-if="infos.ExcursionEtranger.statut == 'Oui'"
                      class="mt-1"
                      md="10"
                    >
                      <b-form-group
                        label-cols="5"
                        label-cols-lg="3"
                        label="Le nombre"
                        class="text-right"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            v-model.number="infos.ExcursionEtranger.nombre"
                            type="number"
                            min="1"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col v-else class="mt-1" md="6" />
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </div>

          <div v-if="infos.voyagesDernierTrimestre == 'Oui'">
            <!-- voyage bénin  -->
            <div id="voyagesBenin" v-if="infos.voyageBenin.statut == 'Oui'">
              <b-card>
                <b-card-header class="d-flex justify-content-between">
                  <h3>DÉTAILS SUR LES VOYAGES ET LES DEPENSES AU BENIN</h3>
                </b-card-header>
                <hr />
                <b-card-body>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">301</th>
                          <th scope="col">N° de série du voyage</th>
                          <th scope="col">Voyage(1)</th>
                          <th scope="col">Voyage(2)</th>
                          <th scope="col">Voyage(3)</th>
                          <th scope="col">Voyage(4)</th>
                          <th scope="col">Voyage(5)</th>
                          <th scope="col">Voyage(6)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- nbre personnes voyages -->
                        <tr>
                          <th scope="row">302</th>
                          <td>Nb de personnes ayant effectué ce voyage</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].nombrePersonne
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[1].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[2].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[3].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[4].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[5].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- motif voyage -->
                        <tr>
                          <th scope="row"></th>
                          <td>Motif principal du voyage</td>

                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau1[0].motifVisite"
                                  :options="optionsMotifVisite"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[1].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[2].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[3].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[4].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[5].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- destination principale -->
                        <tr>
                          <th scope="row">303</th>
                          <td>Quelle a été la destination principale ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].destinationPrincipale
                                "
                                type="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                        </tr>
                        <!-- distance km -->
                        <tr>
                          <th scope="row">304</th>
                          <td>Quelle a été la distance ? (Km)</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="infos.tableau1[0].distanceKm"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[1].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[2].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[3].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[4].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[5].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- type transport -->
                        <tr>
                          <th scope="row">305</th>
                          <td>Type de transport</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau1[0].typeTransport"
                                  :options="optionsTypeTransport"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[1].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[2].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[3].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[4].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[5].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- nobre nuite -->
                        <tr>
                          <th scope="row">306</th>
                          <td>Nombre de nuits ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="infos.tableau1[0].nombreNuits"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[1].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[2].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[3].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[4].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[5].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- lieu residence -->
                        <tr>
                          <th scope="row">307</th>
                          <td>Où avez-vous dormi ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau1[0].lieuResidence"
                                  :options="optionsLieuResidence"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[1].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[2].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[3].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[4].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[5].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- forfait touristique -->
                        <tr>
                          <th scope="row">308</th>
                          <td>
                            S’agissait-il d’un forfait touristique tout compris
                            ?
                          </td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau1[0].forfaitTouristique"
                                  :options="optionsOuiNon"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[1].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[2].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[3].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[4].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[5].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- montant du forfait -->
                        <tr>
                          <th scope="row">309</th>
                          <td>
                            Montant total payé pour le forfait touristique ?
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[0].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[1].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[2].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[3].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[4].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[5].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- cout total du voyage -->
                        <tr>
                          <th scope="row">310</th>
                          <td>Cout total du voyage ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].coutTotalVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[1].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[2].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[3].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[4].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau1[5].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- poste de dépenses -->
                        <tr>
                          <th scope="row" rowspan="8">311</th>
                          <td>1. Hébergement</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].posteDepenseHebergement
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>2. Repas et boissons</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].posteDepenseRepasBoissons
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            3. Achats liés au voyage touristique (nécessaires de
                            voyage, cadeaux, souvenirs, etc.)
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].posteDepenseAchatsVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>4. Activités culturelles</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0]
                                    .posteDepenseActivitesCulturelles
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>5. Activités sportives</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0]
                                    .posteDepenseActivitesSportives
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>6. Dépenses médicales</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0]
                                    .posteDepenseDepensesMedicales
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>7. Education/Formation</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0]
                                    .posteDepenseEducationFormation
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>8. Autres</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].posteDepenseAutres
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- nbre de visites -->
                        <tr>
                          <th scope="row">312</th>
                          <td>
                            Combien de fois avez-vous voyagé vers cette
                            destination?
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau1[0].nombreVoyagesVersDestination
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[1].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[2].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[3].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[4].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau1[5].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- activite réalisee -->
                        <tr>
                          <th scope="row">313</th>
                          <td>Quelles activités avez-vous réalisés ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-checkbox-group
                                  v-model="infos.tableau1[0].activitesRealisees"
                                  :options="optionsActivites"
                                  class="demo-inline-spacing"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau1[1].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau1[2].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau1[3].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau1[4].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau1[5].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- satisfactions -->
                        <tr>
                          <th scope="row">314</th>
                          <td>Satisfaction quant à votre voyage ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau1[0].satisfactionVoyage"
                                  :options="optionsSatisfaction"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[1].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[2].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[3].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[4].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau1[5].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <!-- voyage étranger -->
            <div
              id="voyagesEtranger"
              v-if="infos.voyageEtranger.statut == 'Oui'"
            >
              <b-card>
                <b-card-header class="d-flex justify-content-between">
                  <h3>DÉTAILS SUR LES VOYAGES ET LES DEPENSES A L'ETRANGER</h3>
                </b-card-header>
                <hr />
                <b-card-body>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">401</th>
                          <th scope="col">N° de série du voyage</th>
                          <th scope="col">Voyage(1)</th>
                          <th scope="col">Voyage(2)</th>
                          <th scope="col">Voyage(3)</th>
                          <th scope="col">Voyage(4)</th>
                          <th scope="col">Voyage(5)</th>
                          <th scope="col">Voyage(6)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- nbre personnes voyages -->
                        <tr>
                          <th scope="row">402</th>
                          <td>Nb de personnes ayant effectué ce voyage</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].nombrePersonne
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[1].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[2].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[3].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[4].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[5].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- motif voyage -->
                        <tr>
                          <th scope="row"></th>
                          <td>Motif principal du voyage</td>

                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau2[0].motifVisite"
                                  :options="optionsMotifVisite"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[1].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[2].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[3].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[4].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[5].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- destination principale -->
                        <tr>
                          <th scope="row">403</th>
                          <td>Quelle a été la destination principale ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].destinationPrincipale
                                "
                                type="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                        </tr>
                        <!-- distance km -->
                        <tr>
                          <th scope="row">404</th>
                          <td>Quelle a été la distance ? (Km)</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="infos.tableau2[0].distanceKm"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[1].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[2].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[3].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[4].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[5].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- type transport -->
                        <tr>
                          <th scope="row">405</th>
                          <td>Type de transport</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau2[0].typeTransport"
                                  :options="optionsTypeTransport"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[1].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[2].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[3].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[4].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[5].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- nobre nuite -->
                        <tr>
                          <th scope="row">406</th>
                          <td>Nombre de nuits ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="infos.tableau2[0].nombreNuits"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[1].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[2].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[3].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[4].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[5].nombreNuits"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- lieu residence -->
                        <tr>
                          <th scope="row">407</th>
                          <td>Où avez-vous dormi ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau2[0].lieuResidence"
                                  :options="optionsLieuResidence"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[1].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[2].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[3].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[4].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[5].lieuResidence"
                                :options="optionsLieuResidence"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- forfait touristique -->
                        <tr>
                          <th scope="row">408</th>
                          <td>
                            S’agissait-il d’un forfait touristique tout compris
                            ?
                          </td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau2[0].forfaitTouristique"
                                  :options="optionsOuiNon"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[1].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[2].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[3].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[4].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[5].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- montant du forfait -->
                        <tr>
                          <th scope="row">409</th>
                          <td>
                            Montant total payé pour le forfait touristique ?
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[0].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[1].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[2].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[3].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[4].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[5].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- cout total du voyage -->
                        <tr>
                          <th scope="row">410</th>
                          <td>Cout total du voyage ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].coutTotalVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[1].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[2].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[3].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[4].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau2[5].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- poste de dépenses -->
                        <tr>
                          <th scope="row" rowspan="8">311</th>
                          <td>1. Hébergement</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].posteDepenseHebergement
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>2. Repas et boissons</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].posteDepenseRepasBoissons
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            3. Achats liés au voyage touristique (nécessaires de
                            voyage, cadeaux, souvenirs, etc.)
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].posteDepenseAchatsVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>4. Activités culturelles</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0]
                                    .posteDepenseActivitesCulturelles
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>5. Activités sportives</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0]
                                    .posteDepenseActivitesSportives
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>6. Dépenses médicales</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0]
                                    .posteDepenseDepensesMedicales
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>7. Education/Formation</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0]
                                    .posteDepenseEducationFormation
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>8. Autres</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].posteDepenseAutres
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- nbre de visites -->
                        <tr>
                          <th scope="row">412</th>
                          <td>
                            Combien de fois avez-vous voyagé vers cette
                            destination?
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau2[0].nombreVoyagesVersDestination
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[1].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[2].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[3].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[4].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau2[5].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- activite réalisee -->
                        <tr>
                          <th scope="row">413</th>
                          <td>Quelles activités avez-vous réalisés ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <!-- <b-form-radio-group
                                  v-model="infos.tableau2[0].activitesRealisees"
                                  :options="optionsActivites"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                /> -->
                                <b-form-checkbox-group
                                  v-model="infos.tableau2[0].activitesRealisees"
                                  :options="optionsActivites"
                                  class="demo-inline-spacing"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau2[1].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau2[2].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau2[3].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[4].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau2[5].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- satisfactions -->
                        <tr>
                          <th scope="row">405</th>
                          <td>Satisfaction quant au voyage ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau2[0].satisfactionVoyage"
                                  :options="optionsSatisfaction"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[1].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[2].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[3].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[4].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau2[5].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <!-- excursion au benin -->
            <div
              id="excursionsBenin"
              v-if="infos.ExcursionBenin.statut == 'Oui'"
            >
              <b-card>
                <b-card-header class="d-flex justify-content-between">
                  <h3>EXCURSIONS AU BENIN</h3>
                </b-card-header>
                <hr />
                <b-card-body>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">501</th>
                          <th scope="col">N° de série de l'excursion</th>
                          <th scope="col">Excursion(1)</th>
                          <th scope="col">Excursion(2)</th>
                          <th scope="col">Excursion(3)</th>
                          <th scope="col">Excursion(4)</th>
                          <th scope="col">Excursion(5)</th>
                          <th scope="col">Excursion(6)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- nbre personnes voyages -->
                        <tr>
                          <th scope="row">502</th>
                          <td>
                            Nb de personnes ayant effectué cette excursion
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].nombrePersonne
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[1].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[2].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[3].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[4].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[5].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- motif voyage -->
                        <tr>
                          <th scope="row"></th>
                          <td>Motif principal de l'excursion</td>

                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau3[0].motifVisite"
                                  :options="optionsMotifVisite"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[1].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[2].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[3].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[4].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[5].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- destination principale -->
                        <tr>
                          <th scope="row">503</th>
                          <td>Quelle a été la destination principale ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].destinationPrincipale
                                "
                                type="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                        </tr>
                        <!-- distance km -->
                        <tr>
                          <th scope="row">504</th>
                          <td>Quelle a été la distance ? (Km)</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="infos.tableau3[0].distanceKm"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[1].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[2].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[3].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[4].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[5].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- type transport -->
                        <tr>
                          <th scope="row">505</th>
                          <td>Type de transport</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau3[0].typeTransport"
                                  :options="optionsTypeTransport"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[1].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[2].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[3].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[4].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[5].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- forfait touristique -->
                        <tr>
                          <th scope="row">506</th>
                          <td>
                            S’agissait-il d’un forfait touristique tout compris
                            ?
                          </td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau3[0].forfaitTouristique"
                                  :options="optionsOuiNon"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[1].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[2].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[3].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[4].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[5].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- montant du forfait -->
                        <tr>
                          <th scope="row">507</th>
                          <td>
                            Montant total payé pour le forfait excursion ?
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[0].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[1].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[2].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[3].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[4].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[5].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- cout total du voyage -->
                        <tr>
                          <th scope="row">508</th>
                          <td>Cout total de l'excursion ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].coutTotalVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[1].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[2].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[3].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[4].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau3[5].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- poste de dépenses -->
                        <tr>
                          <th scope="row" rowspan="8">509</th>
                          <td>1. Hébergement</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].posteDepenseHebergement
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>2. Repas et boissons</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].posteDepenseRepasBoissons
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            3. Achats liés au voyage touristique (nécessaires de
                            voyage, cadeaux, souvenirs, etc.)
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].posteDepenseAchatsVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>4. Activités culturelles</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0]
                                    .posteDepenseActivitesCulturelles
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>5. Activités sportives</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0]
                                    .posteDepenseActivitesSportives
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>6. Dépenses médicales</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0]
                                    .posteDepenseDepensesMedicales
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>7. Education/Formation</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0]
                                    .posteDepenseEducationFormation
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>8. Autres</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].posteDepenseAutres
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- nbre de visites -->
                        <tr>
                          <th scope="row">510</th>
                          <td>
                            Combien de fois avez-vous voyagé vers cette
                            destination?
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau3[0].nombreVoyagesVersDestination
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[1].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[2].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[3].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[4].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau3[5].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- activite réalisee -->
                        <tr>
                          <th scope="row">511</th>
                          <td>Quelles activités avez-vous réalisés ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-checkbox-group
                                  v-model="infos.tableau3[0].activitesRealisees"
                                  :options="optionsActivites"
                                  class="demo-inline-spacing"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau3[1].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau3[2].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau3[3].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau3[4].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau3[5].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- satisfactions -->
                        <tr>
                          <th scope="row">512</th>
                          <td>Satisfaction quant au voyage ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau3[0].satisfactionVoyage"
                                  :options="optionsSatisfaction"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[1].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[2].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[3].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[4].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau3[5].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
              </b-card>
            </div>
            <!-- excursion à l'etranger -->
            <div
              id="excursionsEtranger"
              v-if="infos.ExcursionEtranger.statut == 'Oui'"
            >
              <b-card>
                <b-card-header class="d-flex justify-content-between">
                  <h3>EXCURSIONS A L'ETRANGER</h3>
                </b-card-header>
                <hr />
                <b-card-body>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">601</th>
                          <th scope="col">N° de série de l'excursion</th>
                          <th scope="col">Excursion(1)</th>
                          <th scope="col">Excursion(2)</th>
                          <th scope="col">Excursion(3)</th>
                          <th scope="col">Excursion(4)</th>
                          <th scope="col">Excursion(5)</th>
                          <th scope="col">Excursion(6)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- nbre personnes voyages -->
                        <tr>
                          <th scope="row">602</th>
                          <td>
                            Nb de personnes ayant effectué cette excursion
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].nombrePersonne
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[1].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[2].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[3].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[4].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[5].nombrePersonne"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- motif voyage -->
                        <tr>
                          <th scope="row"></th>
                          <td>Motif principal de l'excursion</td>

                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau4[0].motifVisite"
                                  :options="optionsMotifVisite"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[1].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[2].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[3].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[4].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[5].motifVisite"
                                :options="optionsMotifVisite"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- destination principale -->
                        <tr>
                          <th scope="row">603</th>
                          <td>Quelle a été la destination principale ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].destinationPrincipale
                                "
                                type="text"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].destinationPrincipale
                              "
                              type="text"
                            />
                          </td>
                        </tr>
                        <!-- distance km -->
                        <tr>
                          <th scope="row">604</th>
                          <td>Quelle a été la distance ? (Km)</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="infos.tableau4[0].distanceKm"
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[1].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[2].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[3].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[4].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[5].distanceKm"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- type transport -->
                        <tr>
                          <th scope="row">605</th>
                          <td>Type de transport</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau4[0].typeTransport"
                                  :options="optionsTypeTransport"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[1].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[2].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[3].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[4].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[5].typeTransport"
                                :options="optionsTypeTransport"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- forfait touristique -->
                        <tr>
                          <th scope="row">606</th>
                          <td>
                            S’agissait-il d’un forfait touristique tout compris
                            ?
                          </td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau4[0].forfaitTouristique"
                                  :options="optionsOuiNon"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[1].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[2].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[3].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[4].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[5].forfaitTouristique"
                                :options="optionsOuiNon"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- montant du forfait -->
                        <tr>
                          <th scope="row">607</th>
                          <td>
                            Montant total payé pour le forfait excursion ?
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[0].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[1].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[2].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[3].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[4].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[5].montantForfait"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- cout total du voyage -->
                        <tr>
                          <th scope="row">608</th>
                          <td>Cout total de l'excursion ?</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].coutTotalVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[1].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[2].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[3].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[4].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="infos.tableau4[5].coutTotalVoyage"
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- poste de dépenses -->
                        <tr>
                          <th scope="row" rowspan="8">609</th>
                          <td>1. Hébergement</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].posteDepenseHebergement
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].posteDepenseHebergement
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>2. Repas et boissons</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].posteDepenseRepasBoissons
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].posteDepenseRepasBoissons
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            3. Achats liés au voyage touristique (nécessaires de
                            voyage, cadeaux, souvenirs, etc.)
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].posteDepenseAchatsVoyage
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].posteDepenseAchatsVoyage
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>4. Activités culturelles</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0]
                                    .posteDepenseActivitesCulturelles
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5]
                                  .posteDepenseActivitesCulturelles
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>5. Activités sportives</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0]
                                    .posteDepenseActivitesSportives
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].posteDepenseActivitesSportives
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>6. Dépenses médicales</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0]
                                    .posteDepenseDepensesMedicales
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].posteDepenseDepensesMedicales
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>7. Education/Formation</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0]
                                    .posteDepenseEducationFormation
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].posteDepenseEducationFormation
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>8. Autres</td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].posteDepenseAutres
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].posteDepenseAutres
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- nbre de visites -->
                        <tr>
                          <th scope="row">610</th>
                          <td>
                            Combien de fois avez-vous voyagé vers cette
                            destination?
                          </td>
                          <td>
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-input
                                v-model.number="
                                  infos.tableau4[0].nombreVoyagesVersDestination
                                "
                                type="number"
                                min="0"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[1].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[2].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[3].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[4].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                          <td>
                            <b-form-input
                              v-model.number="
                                infos.tableau4[5].nombreVoyagesVersDestination
                              "
                              type="number"
                              min="0"
                            />
                          </td>
                        </tr>
                        <!-- activite réalisee -->
                        <tr>
                          <th scope="row">611</th>
                          <td>Quelles activités avez-vous réalisés ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-checkbox-group
                                  v-model="infos.tableau4[0].activitesRealisees"
                                  :options="optionsActivites"
                                  class="demo-inline-spacing"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau4[1].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau4[2].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau4[3].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau4[4].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-checkbox-group
                                v-model="infos.tableau4[5].activitesRealisees"
                                :options="optionsActivites"
                                class="demo-inline-spacing"
                              />
                            </b-row>
                          </td>
                        </tr>
                        <!-- satisfactions -->
                        <tr>
                          <th scope="row">612</th>
                          <td>Satisfaction quant au voyage ?</td>
                          <td>
                            <b-row class="">
                              <validation-provider
                                #default="{ errors }"
                                rules="required"
                              >
                                <b-form-radio-group
                                  v-model="infos.tableau4[0].satisfactionVoyage"
                                  :options="optionsSatisfaction"
                                  class="demo-inline-spacing mb-1 ml-1"
                                  value-field="value"
                                  text-field="text"
                                  disabled-field="disabled"
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[1].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[2].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[3].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[4].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                          <td>
                            <b-row class="">
                              <b-form-radio-group
                                v-model="infos.tableau4[5].satisfactionVoyage"
                                :options="optionsSatisfaction"
                                class="demo-inline-spacing mb-1 ml-1"
                                value-field="value"
                                text-field="text"
                                disabled-field="disabled"
                              />
                            </b-row>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>

          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-2"
              @click="sendToPreview()"
            >
              Aperçu avant enregistrement
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
    <!-- <tips-modal-create-from-last-record :canShow="showCreateFromLastModal"></tips-modal-create-from-last-record> -->
    <!-- <TipsModalCreateFromLastRecord /> -->
    <b-modal
      :ref="tipsModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Créer un formulaire"
      size="lg"
    >
      <!-- <b-card-text>
        <span>We've added the utility class</span>
        <code>'shadow'</code>
        <span>to the modal content for added effect.</span>
      </b-card-text> -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">
              Créer a partir du dernier formulaire enregistré
            </h3>
            <p class="my-1">
              Vous avez la possibilté de récupérer les données du dernier
              formulaire que vous avez enregistré pour remplir ce nouveau
              formulaire.
            </p>
            <b-button
              variant="outline-primary"
              class="float-right"
              @click="fillFormWithLastRecord"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-card>
            <h3 class="text-primary">Créer a partir d'un formulaire vierge</h3>
            <p class="my-1">
              Vous avez la possibilté de remplir le formulaire de zéro.
            </p>

            <b-button
              @click="hideModal"
              variant="outline-primary"
              class="float-right"
            >
              Continuer
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardImg,
  // BFormCheckbox,
  BFormCheckboxGroup,
  // BFormRadio,
  // BForm,
  // BCardText,
  BInputGroupPrepend,
  BInputGroup,
  VBPopover,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { mapActions } from "vuex";
import vSelect from "vue-select";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ContentLoader } from "vue-content-loader";
import localstorageService from "@/services/localstorage/localstorage.service";
import TipsModalCreateFromLastRecord from "@/components/TipsModalCreateFromLastRecord.vue";
import utilsService from "@/services/utils/utils.service";
// imports for validation
// const store = require('store')

// store modules and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import utilsStoreModule from "@/store/utils";
import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "utils", module: utilsStoreModule },
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    BButton,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BCard,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
    BFormCheckboxGroup,
    // BFormRadio,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BCardImg,
    // BCardText,
    // BForm,
    BInputGroupPrepend,
    BInputGroup,
    ContentLoader,
    TipsModalCreateFromLastRecord,
  },
  directives: {
    Ripple,
    "b-popover": VBPopover,
  },
  mixins: [heightTransition],
  props: {
    etablissementDetails: null,
  },
  data() {
    return {
      isFillingForm: false,
      formInput: this.$route.params.data,
      lastRecordedForm: null,
      tipsModalRef: new Date().getTime(),
      showCreateFromLastModal: true,
      currentUserId: localstorageService.getUserId(),
      isCheckingForLastForm: true,
      formType: "formstatagence",
      email,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,

      annees: [],
      mois: [],
      departements: [],
      communes: [],
      arrondissements: [],
      quartiers: [],
      pays: [],

      optionsOuiNon: ["Oui", "Non"],
      optionsNonVoyage: [
        "Pas intéressé",
        "Tarifs élevé de l'hébergement",
        "Tarifs élevé des transports en commun",
        "Insuffisance de transports en commun",
        "Autres",
      ],
      optionsMotifVisite: [
        "Visite de parents d’amis",
        "Funérailles",
        "Vacances, loisirs et détente",
        "Éducation et formation",
        "Santé et soins médicaux",
        "Religion/pèlerinage",
        "Manifestation culturelle ou cultuelle",
        "Achat",
        "Transit",
        "Professionnel",
        "Voyage d’affaire individuel (par exemple, visite d’un fournisseur ou d’un client)",
        "Conférence/congrès/séminaire",
        "Autres",
      ],
      optionsTypeTransport: [
        "Voiture privée",
        "Voiture de location",
        "Voiture de service",
        "Taxi",
        "Transport en commun",
        "Avion",
        "Barque/pirogue",
        "Motocyclette",
        "Autres",
      ],
      optionsLieuResidence: [
        "Hôtel et assimilés",
        "Appartement/Hôtel",
        "Parents/amis",
        "Résidence secondaire",
        "Maison/appartement meublé",
        "Chambre d'hôte",
        "Autres",
      ],
      optionsActivites: [
        "Restaurant/bars/cafés/snack-bars",
        "Achats",
        "Concerts/festival de musique",
        "Monuments",
        "Manifestation culturelle/cultuelle",
        "Randonnée/trekking en forêt/montagne",
        "Visite des parcs nationaux",
        "Golf",
        "Chasse",
        "Manifestation sportive",
        "Théâtre",
        "Evènement familial (mariage, funérailles, baptême, première communion, etc.)",
        "Musées",
        "Zoo/parcs d’attraction",
        "Plage",
        "Formation/conférence/séminaire",
        "Visite de sociétés",
        "Autres",
      ],
      optionsSatisfaction: [
        "Très mécontent",
        "Mécontent",
        "Ni mécontent ni content",
        "Content",
        "Très Content",
      ],

      infos: {
        firstname: null,
        lastname: null,
        voyagesDernierTrimestre: null,
        raisonNonVoyage: null,
        voyageBenin: {
          statut: null,
          nombre: 0,
        },
        voyageEtranger: {
          statut: null,
          nombre: 0,
        },
        ExcursionBenin: {
          statut: null,
          nombre: 0,
        },
        ExcursionEtranger: {
          statut: null,
          nombre: 0,
        },
        // tableaux
        tableau1: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
        ],
        tableau2: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            nombreNuits: null,
            lieuResidence: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalVoyage: null,
            posteDepenseHebergement: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsVoyage: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreVoyagesVersDestination: null,
            activitesRealisees: [],
            satisfactionVoyage: null,
          },
        ],
        tableau3: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
        ],
        tableau4: [
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
          {
            nombrePersonne: null,
            motifVisite: null,
            destinationPrincipale: null,
            distanceKm: null,
            typeTransport: null,
            forfaitTouristique: null,
            montantForfait: null,
            coutTotalExcursion: null,
            posteDepenseRepasBoissons: null,
            posteDepenseAchatsMagasins: null,
            posteDepenseAchatsExcursion: null,
            posteDepenseActivitesCulturelles: null,
            posteDepenseActivitesSportives: null,
            posteDepenseDepensesMedicales: null,
            posteDepenseEducationFormation: null,
            posteDepenseAutres: null,
            nombreExcursionsVersDestination: null,
            activitesRealisees: [],
            satisfactionExcursion: null,
          },
        ],
      },
      raisonNonVoyageAutre: null,

      validOutput: {},

      agrements: [
        { etablissement: "Les Hirondelles", reference: "hg7h878jhjKJ" },
      ],
      invalideAgrement: false,
      mauvaisEtablissement: false,
      invalideIfu: false,
      auteur: null,
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left")
        return "layout-content-renderer-left";
      if (rendererType === "sidebar-left-detached")
        return "layout-content-renderer-left-detached";
      return "layout-content-renderer-default";
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        setTimeout(() => {
          this.isFillingForm = false;
          this.isCheckingForLastForm = false;
          this.isFillingForm = false;
        }, 1000);
        const userId = localstorageService.getUserId();
        const etablissementDetails = this.etablissementDetails || {};
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions("params", {
      fetchAnnees: "fetchAnnees",
    }),
    ...mapActions("formulaire", {
      getLastFormCreatedByUser: "getLastFormCreatedByUser",
    }),
    ...mapActions("utils", {
      checkIFU: "checkIFU",
    }),
    ...mapActions("params", {
      action_loadDepartments: "loadDepartments",
      action_loadCommunes: "loadCommunes",
      action_loadArrondissements: "loadArrondissements",
      action_loadNeighborhoods: "loadNeighborhoods",
    }),
    ...mapActions("etablissements", {
      createEtablissement: "createEtablissement",
      action_getListTypesEtablissement: "getListTypesEtablissement",
    }),
    showModal() {
      this.$refs[this.tipsModalRef].show();
    },
    hideModal() {
      this.$refs[this.tipsModalRef].hide();
    },
    fillFormWithLastRecord() {
      this.isFillingForm = true;
      this.infos = this.lastRecordedForm;
      setTimeout(() => {
        this.isFillingForm = false;
      }, 1000);
      this.hideModal();
    },
    sendToPreview() {
      this.$emit("onFormSucess", {
        routerParams: { preview: true, data: this.infos },
      });
      return;
      this.$refs.formRules.validate().then(async (success) => {
        const { currentUserId } = utilsService.currentUserUtils();
        if (success) {
          let canSend = true;
          // clearing data
          const form = {
            ...this.infos,
            auteur: currentUserId ? currentUserId : "",
          };
          if (form.qrcode) delete form.qrcode;
          if (form._id) delete form._id;
          if (this.raisonNonVoyageAutre)
            form.raisonNonVoyage = this.raisonNonVoyageAutre;
          // console.log('---------------  form: ', form);
          if (canSend) {
            this.$emit("onFormSucess", {
              routerParams: { preview: true, data: form },
            });
          }
        } else {
          const errorFirst = this.$el.querySelector(".is-invalid");
          if (errorFirst) {
            errorFirst.scrollIntoView({ behavior: "instant" });
            errorFirst.focus();
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/bordered-layout.scss";
</style>